import { BUTTON_TYPES, Button } from '../Button/Button';

// styles
import styles from './videoPlayButton.scss';

interface VideoPlayButtonProps {
  playVideo: () => void;
  videoPlayButtonLabel?: string;
}

const VideoPlayButton = ({
  playVideo,
  videoPlayButtonLabel = 'Play Video',
}: Readonly<VideoPlayButtonProps>) => (
  <Button
    symbol=""
    type={BUTTON_TYPES.PLAIN}
    aria-label={videoPlayButtonLabel}
    onClick={playVideo}
    className={styles.videoPlayButton}
  />
);

export default VideoPlayButton;
